import React from "react"

export default function Header() {
  return (
    <div className="header-wrap">
      <div className="header">
        <div className="header-container">
          <div className="header-content">Fast Habit Print</div>
        </div>
      </div>
    </div>
  )
}
