import React from "react"

export default function Form({ input, onChange, almostTooMany, getWordCount }) {
  return (
    <div className="form-container">
      <form action="" onSubmit={e => e.preventDefault()}>
        <label className="input-label" htmlFor="input">
          Enter your daily habit
        </label>
        <input
          type="text"
          className="input"
          id="input"
          value={input}
          onChange={e => onChange(e)}
          placeholder="e.g. - Do 3 sets of pushups"
        />
        <div className="word-count" style={{ color: almostTooMany ? "red" : "#3661ea" }}>
          {getWordCount()}
        </div>
      </form>
    </div>
  )
}
