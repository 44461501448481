import React, { useRef, useState } from "react"
import "./App.css"
import Hero from "./Hero"
import Header from "./Header"
import Week from "./Week"
import healthy from "./healthy.svg"
import Footer from "./Footer"
import Form from "./Form"
import ReactToPrint from "react-to-print"
import Modal from "react-modal"
import { MobileView } from "react-device-detect"
import ReactGA from "react-ga"

function initializeReactGA() {
  ReactGA.initialize("UA-147717747-1")
  ReactGA.pageview("/")
}

class ComponentToPrint extends React.Component {
  getClassName = () => {
    const { browser } = this.props
    initializeReactGA()
    if (browser === "Chrome") {
      return "habit"
    }
    if (browser === "Safari") {
      return "habit-safari"
    } else {
      return "habit"
    }
  }

  render() {
    return (
      <div className="print-container">
        <div className={this.getClassName()}>{this.props.habit}</div>
        <Week count={10} />
      </div>
    )
  }
}

function getBrowserName() {
  var name = "Unknown"
  if (navigator.userAgent.indexOf("MSIE") !== -1) {
    name = "MSIE"
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    name = "Firefox"
  } else if (navigator.userAgent.indexOf("Opera") !== -1) {
    name = "Opera"
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    name = "Chrome"
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    name = "Safari"
  }
  return name
}

Modal.setAppElement("#root")

function App() {
  const componentRef = useRef()
  const [input, setInput] = useState("")
  const [modal, setModal] = useState(false)
  const [almostTooMany, setAlmostTooMany] = useState(false)
  const [browser] = useState(getBrowserName())

  const openModal = () => {
    setModal(true)
    ReactGA.event({
      category: "Navigation",
      action: "Opened Preview Modal"
    })
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  function closeModal() {
    setModal(false)
  }

  function getWordCount() {
    return input && input.length + "/35"
  }

  function typing(e) {
    setAlmostTooMany(false)
    if (input.length > 20) {
      setAlmostTooMany(true)
    }
    if (input.length < 35) {
      setInput(e.target.value)
    }
  }

  return (
    <div className="App">
      <Header />
      <div className="body">
        <div className="content-container">
          <main>
            <MobileView>
              <div className="mobile-warning">
                <h3> Mobile Warning: </h3>
                <p>This site doesn't work on mobile chrome. Works best on desktop or safari.</p>
              </div>
            </MobileView>
            <Hero />
            <Form
              input={input}
              onChange={typing}
              almostTooMany={almostTooMany}
              getWordCount={getWordCount}
            />
            <div className="print-button">
              <button onClick={openModal}>Preview</button>
            </div>
            <Modal
              isOpen={modal}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              contentLabel="Example Modal"
            >
              <div style={{ marginLeft: "60px" }}>
                <button
                  style={{ backgroundColor: "#345", marginRight: "30px" }}
                  onClick={() => closeModal()}
                >
                  cancel
                </button>
                <ReactToPrint
                  trigger={() => <button>Print page </button>}
                  content={() => componentRef.current}
                  copyStyles={true}
                />
              </div>

              <ComponentToPrint ref={componentRef} habit={input} browser={browser} />
            </Modal>
            <div className="print-button"></div>
          </main>
        </div>
        <img src={healthy} alt="healthy people doing healthy things" style={{ width: "80%" }} />
      </div>
      <Footer />
    </div>
  )
}

export default App
