import React from "react"

export default function Week(props) {
  const week = () => {
    return (
      <>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </>
    )
  }
  const weekLabels = (
    <div className="weekday">
      <div className="day">M</div>
      <div className="day">T</div>
      <div className="day">W</div>
      <div className="day">T</div>
      <div className="day">F</div>
      <div className="day">S</div>
      <div className="day">S</div>
    </div>
  )

  const getWeeks = () => {
    let weekArr = [weekLabels]

    for (let i = 0; i < props.count; i++) {
      weekArr.push(week())
    }
    return weekArr.map((els, i) => {
      return (
        <div className="weekday" key={i}>
          {" "}
          {els}{" "}
        </div>
      )
    })
  }

  return <div>{getWeeks()}</div>
}
