import React from "react"

export default function Footer() {
  return (
    <div className="footer">
      Concept based on <a href="https://goodhabitprint.com">Good Habit Print</a> by the awesome{" "}
      <a href="https://www.pixelswithin.com/">Diana Lopez</a> (
      <a href="https://twitter.com/pixelswithin">@pixelswithin</a>). <br />
      Built by <a href="https://twitter.com/jordanhdvs">Jordan Davis</a>. <br />
      <div className="copyright">Copyright 2019 Jordan Davis.</div>
      <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value="2PZ583UPM79SA" />
        <input
          type="image"
          src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif"
          className="donate"
          border="0"
          name="submit"
          alt="PayPal - The safer, easier way to pay online!"
        />
        <img
          alt=""
          border="0"
          src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
          width="1"
          height="1"
        />
      </form>
    </div>
  )
}
