import React from "react"

export default function Hero() {
  return (
    <div className="hero">
      <div className="hero-copy">
        <div className="hero-headline">Print off a page & track your habits in one minute.</div>
        <div className="hero-body-copy">
          Enter your habit, and print off a page to track it. Post it somewhere visible and cross
          off the days with a big red marker.
        </div>
      </div>
    </div>
  )
}
